import React from "react";

import SEO from "../components/SEO";
import LearnMore from "../components/partials/index/LearnMoreSection";
import Features from "../components/partials/schoolleader/FeaturesSection";
import HeroSection from "../components/partials/teacherlp/HeroFeaturesSection";

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Remote Learning on ClassDojo"
        description="ClassDojo brings your school together, wherever you are, free"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Remote Learning on ClassDojo",
          description: "ClassDojo brings your school together, wherever you are, free",
        }}
      />
      <HeroSection />
      <LearnMore hideCta />
      <Features />
    </>
  );
};
export default IndexPage;
